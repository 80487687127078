module controllers {
    export module master {

        interface IAuthDashboardCtrlScope extends ng.IScope {
        }

        interface IAuthDashboardParams extends ng.ui.IStateParamsService {
        }

        export class authDashboardCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "$timeout",
                '$uibModal',
                '$state',
                '$stateParams',
                '$transitions',
            ];
           

            ctrlName: string;
            myHook: any;

            constructor(private $scope: IAuthDashboardCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private $state: ng.ui.IStateService,
                private $stateParams: IAuthDashboardParams,
                private $transitions: ng.ui.core.ITransition,
            ) {

                this.ctrlName = 'Authorisation Dashboard Controller';

                this.myHook = $transitions.onSuccess({
                    to: 'auth.AuthDashboard',
                    //from: 'auth.MasterData.Company.**'
                }, () => {
                    //this.loadCompanies();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }
        }

        angular.module("app").controller("authDashboardCtrl", controllers.master.authDashboardCtrl);
    }
}
                

              